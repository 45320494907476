import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import React from 'react';
import { DISCUSSION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import DropdownButton from '../dropdown-button';
import DiscussionPostButton from '../create-discussion-button';
import QuestionPostButton from '../create-question-button';
import { HorizontalSeparator, VerticalSeparator } from '../separator';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withDeviceType from '../../hoc/with-device-type';
import styles from './create-post-button.scss';

const CreatePostButton = ({ categorySlug, postTypes, isDropdown, borderWidth, isMobile }) => {
  const renderNewPostDropdown = () =>
    isDropdown ? (
      <DropdownButton
        label="create-post-button.create-new-post"
        className={styles.dropdownButtons}
        dataHook={'create-post-cta'}
      >
        <DiscussionPostButton categorySlug={categorySlug} isExtended />
        <HorizontalSeparator />
        <QuestionPostButton categorySlug={categorySlug} isExtended />
      </DropdownButton>
    ) : (
      <div
        className={classNames(
          styles.buttons,
          'forum-card-border-color',
          'forum-card-background-color',
        )}
        style={{ borderWidth }}
      >
        <DiscussionPostButton categorySlug={categorySlug} isExtended />
        {isMobile ? (
          <HorizontalSeparator className={styles.horizontalSeparator} />
        ) : (
          <VerticalSeparator />
        )}
        <QuestionPostButton categorySlug={categorySlug} isExtended />
      </div>
    );

  if (postTypes.length > 1) return renderNewPostDropdown();
  return (
    <div>
      {postTypes[0] === DISCUSSION ? (
        <DiscussionPostButton categorySlug={categorySlug} />
      ) : (
        <QuestionPostButton categorySlug={categorySlug} />
      )}
    </div>
  );
};

CreatePostButton.propTypes = {
  categorySlug: PropTypes.string,
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  isDropdown: PropTypes.bool,
  borderWidth: PropTypes.number.isRequired,
  isMobile: PropTypes.bool,
};

CreatePostButton.defaultProps = {
  postTypes: ['discussion', 'question'],
  isDropdown: true,
};

export default flowRight(withCardBorderWidth, withDeviceType)(CreatePostButton);
