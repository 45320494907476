import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import CardHeaderLeftSide from './card-header-left-side';
import UserAvatarImage from '../user-avatar-image';
import ProfileLink from '../link/profile-link';
import { PinIcon } from '../icons/pin-icon';
import MemberCardAsync from '../../containers/member-card-async';
import styles from './post-page-card-header.scss';

class PostPageCardHeader extends Component {
  renderPinIcon = () => (
    <div data-hook="pin-icon">
      <PinIcon
        className="icon-secondary-fill"
        aria-label={this.props.t('post-list-item.pinned-post')}
        isPinned
      />
    </div>
  );
  render() {
    const { post } = this.props;
    const user = post.owner;
    const date = post.createdDate;
    return (
      <div className={styles.header}>
        <div className={styles.headerLeftSide}>
          <ProfileLink user={user}>
            <MemberCardAsync
              className={styles.avatar}
              viewedMemberId={user.siteMemberId}
              viewedMemberSlug={user.slug}
            >
              <UserAvatarImage user={user} type={'postPage'} />
            </MemberCardAsync>
          </ProfileLink>
          <CardHeaderLeftSide user={user} date={date} />
        </div>
        <div className={styles.headerRightSide}>{post.isPinned && this.renderPinIcon()}</div>
      </div>
    );
  }
}

PostPageCardHeader.propTypes = {
  post: PropTypes.object.isRequired,
};

export default flowRight(withTranslate)(PostPageCardHeader);
