import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { LIKE_POST } from '../../constants/interactions';
import LikeButton from '../like-button';
import ProtectedButton from '../../containers/protected-button';
import { HorizontalSeparator } from '../separator';
import { isCacheableRendering } from '../../../common/store/basic-params/basic-params-selectors';
import { DISCUSSION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import styles from './post-footer.scss';
import PostListItemRecentActivity from '../post-list-item-recent-activity';
import { ViewCountIcon } from '../icons/view-count-icon';
import { CommentIconSmall } from '../icons/comment-icon-small';

import IconTooltip from '../icon-tooltip';
import { CommentDisabledIconSmall } from '../icons/comment-disabled-icon-small';
import CounterNumber from '../counter-number';
import { getPostLikeCount } from '../../selectors/post-selectors';

const PostFooter = ({
  post,
  category,
  forPublicUser,
  onLikeClick,
  Link,
  postLink,
  t,
  contentFontClassName,
  isViewsCountEnabled,
  type,
  interactionStarted,
  showViewCount,
  userEventsClickLike,
  showRecentActivity,
  likeCount,
}) => {
  const handleLikeClick = e => {
    userEventsClickLike({
      type: 'post',
      _id: post._id,
      isLiked: post.isLiked,
    });
    e.preventDefault();
    interactionStarted(LIKE_POST);
    onLikeClick(`${post._id}`);
  };
  const isDiscussion = post.postType === DISCUSSION;

  return (
    <div
      className={classNames(styles.footer, styles[type], {
        [styles.withRecentActivity]: showRecentActivity,
      })}
    >
      {!showRecentActivity && <HorizontalSeparator className={styles.searchSeparator} />}
      <div className={styles.footerContent}>
        <div className={styles.group}>
          {isViewsCountEnabled && (
            <div className={styles.numberElementWithIcon} data-hook="post-list-item__view-count">
              <ViewCountIcon title={t('subcategory-list-item.views')} className="forum-icon-fill" />
              <span className={classNames(styles.numberElementText)}>
                {showViewCount ? (
                  <CounterNumber entityId={post._id} viewCount />
                ) : (
                  <span>&nbsp;</span>
                )}
              </span>
            </div>
          )}

          <Link
            className={classNames(
              styles.commentCount,
              'forum-text-color',
              'forum-link-hover-color',
            )}
            to={`${postLink}`}
            data-hook="post-list-item__comment-count"
          >
            <div
              className={classNames(styles.numberElementWithIcon, styles.commentsNumber)}
              data-hook="post-list-item__comment-count"
            >
              {post.isCommentsDisabled ? (
                <IconTooltip text={t('post-list-item.post-closed')} className={styles.tooltip}>
                  <CommentDisabledIconSmall className="forum-icon-fill" />
                </IconTooltip>
              ) : (
                <CommentIconSmall
                  title={t(
                    isDiscussion ? 'post-list-item.total-comments' : 'post-list-item.total-answers',
                    {
                      count: post.totalComments || 0,
                    },
                  )}
                  className="forum-icon-fill"
                />
              )}
              <span className={classNames(styles.numberElementText)}>
                <CounterNumber
                  initialValue={post.totalComments}
                  entityId={post._id}
                  totalComments
                />
              </span>
            </div>
          </Link>
        </div>
        <div className={styles.likeContainer}>
          <span
            className={classNames(styles.likeCount, 'forum-text-color')}
            data-hook="post-list-item__like-count"
          >
            <CounterNumber
              tKey="post-list-item.like-count"
              initialValue={likeCount}
              entityId={post._id}
              likeCount
            />
          </span>
          <ProtectedButton
            className={classNames(styles.likeButton, contentFontClassName)}
            onClick={forPublicUser(handleLikeClick, { preventDefault: true })}
            data-hook="post-list-item__like-button"
            actionDetails={{ action: LIKE_POST, args: [post._id] }}
          >
            <LikeButton
              isLiked={post.isLiked}
              likeCount={likeCount}
              isDisabled={post.isLikeInProgress}
            />
          </ProtectedButton>
        </div>
      </div>
      {showRecentActivity && <HorizontalSeparator />}
      {showRecentActivity && (
        <div className={styles.recentActivity}>
          <span className={styles.recentActivityTitle}>
            {t('post-list-footer.recent-activity')}:
          </span>
          <PostListItemRecentActivity post={post} category={category} />
        </div>
      )}
    </div>
  );
};

PostFooter.MEMBERS_AREA = 'membersArea';
PostFooter.FOOTER_POST = 'footerPost';

PostFooter.propTypes = {
  forPublicUser: PropTypes.func.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  Link: PropTypes.func.isRequired,
  postLink: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  contentFontClassName: PropTypes.string,
  type: PropTypes.string,
  isViewsCountEnabled: PropTypes.bool,
  interactionStarted: PropTypes.func,
  showViewCount: PropTypes.bool,
  showRecentActivity: PropTypes.bool,
  userEventsClickLike: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  interactionStarted: actions.interactionStarted,
  showViewCount: !isCacheableRendering(state),
  userEventsClickLike: actions.userEventsClickLike,
  likeCount: getPostLikeCount(state, ownProps.post._id),
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(PostFooter);
